// extracted by mini-css-extract-plugin
export var content = "ParallaxHero-module--content--aad17";
export var phoneStarLeft = "ParallaxHero-module--phoneStarLeft--ed0ac";
export var phoneStarMain = "ParallaxHero-module--phoneStarMain--320a0";
export var phoneStarRight = "ParallaxHero-module--phoneStarRight--91d74";
export var star = "ParallaxHero-module--star--e6f2b";
export var star1 = "ParallaxHero-module--star1--d66d2";
export var star10 = "ParallaxHero-module--star10--c0068";
export var star11 = "ParallaxHero-module--star11--e75d6";
export var star12 = "ParallaxHero-module--star12--f1dcb";
export var star2 = "ParallaxHero-module--star2--172d0";
export var star3 = "ParallaxHero-module--star3--0b49e";
export var star4 = "ParallaxHero-module--star4--5549c";
export var star5 = "ParallaxHero-module--star5--9620c";
export var star6 = "ParallaxHero-module--star6--74d41";
export var star7 = "ParallaxHero-module--star7--24ab1";
export var star8 = "ParallaxHero-module--star8--cd62e";
export var star9 = "ParallaxHero-module--star9--fae55";
export var starLayer1 = "ParallaxHero-module--starLayer1--db4dd";
export var starLayer2 = "ParallaxHero-module--starLayer2--737f7";
export var starLayer3 = "ParallaxHero-module--starLayer3--d0e42";
export var wrapper = "ParallaxHero-module--wrapper--930ef";